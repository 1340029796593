import { appsFacadeApi } from 'api'

export interface Props {
  tenantId: string
  notification: CreateNotificationPayload
}

export interface CreateNotificationPayload {
  title: string
  body: string
  recipients: { type: string; values: string[] }[]
  deliveryChannels: string[]
}

export const createAppsNotification = ({ tenantId, notification }: Props) =>
  appsFacadeApi.post('/notifications/apps', notification, { headers: { 'Tenant-Id': tenantId } })
