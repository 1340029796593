import { useOs } from '@wpp-open/react'
import { useEffect, PropsWithChildren, useState } from 'react'

import { assistantApi, appsFacadeApi } from 'api'

const apiInstances = [assistantApi, appsFacadeApi]

export const ApiProvider = ({ children }: PropsWithChildren<{}>) => {
  const [isReady, setIsReady] = useState(false)
  const { osApi, osContext } = useOs()

  const tenantId = osContext.tenant.id

  useEffect(() => {
    const interceptors = apiInstances.map(instance =>
      instance.client.interceptors.request.use(
        instanceConfig => {
          const bearer = osApi.getAccessToken()
          if (bearer) {
            instanceConfig!.headers!.Authorization = `Bearer ${bearer}`
            // instanceConfig!.headers!.Authorization =
            //   'Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJSWTNGSWRndVNiSDZFYzJiUS0xQ2VwY1k5RDlXT1ZiWk15Vy1VdVQwUEFnIn0.eyJleHAiOjE3MDk4NDAzNDMsImlhdCI6MTcwOTgwNDM0OSwiYXV0aF90aW1lIjoxNzA5ODA0MzQzLCJqdGkiOiI2ZGFlZDcwOC0wYWIzLTRkOGUtODBiYS0wYjFjYjNmMzE3MDYiLCJpc3MiOiJodHRwczovL2F1dGgub3Mud3BwLmNvbS9hdXRoL3JlYWxtcy9hei1iZXRhIiwiYXVkIjoiYWNjb3VudCIsInN1YiI6IjNkNmYxOGM1LWYyYmUtNGY4MC04NmM4LTg4YTYwNDdkNTM4ZiIsInR5cCI6IkJlYXJlciIsImF6cCI6ImJveC1zdGFnaW5nLWZlIiwic2Vzc2lvbl9zdGF0ZSI6ImViODAzOTE3LWFmYzktNDA4My04ZDM3LWU5ODA4NjEyODYxYiIsInJlYWxtX2FjY2VzcyI6eyJyb2xlcyI6WyJvZmZsaW5lX2FjY2VzcyIsInVtYV9hdXRob3JpemF0aW9uIiwiZGVmYXVsdC1yb2xlcy1hei1iZXRhIl19LCJyZXNvdXJjZV9hY2Nlc3MiOnsiYWNjb3VudCI6eyJyb2xlcyI6WyJtYW5hZ2UtYWNjb3VudCIsIm1hbmFnZS1hY2NvdW50LWxpbmtzIiwidmlldy1wcm9maWxlIl19fSwic2NvcGUiOiJvcGVuaWQgZW1haWwgcHJvZmlsZSIsInNpZCI6ImViODAzOTE3LWFmYzktNDA4My04ZDM3LWU5ODA4NjEyODYxYiIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwibmFtZSI6Ik1paGEgS29yb3NlYyIsInByZWZlcnJlZF91c2VybmFtZSI6Im1paGEua29yb3NlY0B3cHAuY29tIiwiZ2l2ZW5fbmFtZSI6Ik1paGEiLCJmYW1pbHlfbmFtZSI6Iktvcm9zZWMiLCJlbWFpbCI6Im1paGEua29yb3NlY0B3cHAuY29tIn0.c-xfe-K4HVVwyLmKjOYq3xgbzXZ65nPeZDj_2fG5TQ9ZbwqXDozc4PBxbSuYOSeU6WlrT3LMXJ-ls-Dt_B7Paz-M--5bs0ujUrsvHEVWUk9YL_PMrLBEScW0fk5CQ_Jz4FdKmlyH82M3Bco02rzcTGKT66nwvnfWFrjMEkALxT_vGEC2h2cA2TgfI258nD_jxoNGNelrV-YVOjo-pVUGWYH1H9RMP7jfQIHGRAU6ZoqxmkpS97yNEa63CSO17yRDDfxrtuN6u6a3tNvaVX-wQEDfsXul_iodz3JfKb1pzHbcGJ5aexUsJzHa06Ed2sO8q1wgVMlED8tuFXlu_1YNqw'
          }

          if (tenantId) {
            instanceConfig!.headers!['X-Tenant-Id'] = tenantId
          }

          return instanceConfig
        },
        error => Promise.reject(error),
      ),
    )

    setIsReady(true)

    return () => {
      interceptors.forEach((interceptor, index) => {
        apiInstances[index].client.interceptors.request.eject(interceptor)
      })
    }
    // eslint-disable-next-line
  }, [osApi.getAccessToken])

  return <>{isReady && children}</>
}
